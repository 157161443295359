import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Spacer,
  Text,
  useDisclosure,
  Tr,
  Td,
  useTheme
} from "@chakra-ui/react";
import { TokenTable, Loader } from "../../../Components";
import Tooltip from "../../../Components/Tooltip";

import { getNum, format } from "../../../Utils/number";
import { useProtocolDataContext } from "../../../Hooks/useProtocolDataContext";

export type TroveListProps = {
};


const TroveList: React.FC<TroveListProps> = () => {


  const { sortedTrovesData } = useProtocolDataContext()

  const numberOfTroves = sortedTrovesData.length

  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();

  const [idx, setIdx] = useState<number>(0)


  const onClickLeft = () => {
    if (idx > 0) {
      setIdx(idx - 1)
    }


  }

  const onClickRight = () => {
    if (idx < numberOfTroves / 10 - 1) {
      setIdx(idx + 1)
    }
  }

  return (
    <>
      <Box layerStyle="card" flex={1}>

        <Flex flex={2}>
          <Text textStyle="title3">Sorted Troves <Tooltip> Sorted by AICR</Tooltip></Text>
          <Spacer />
          <Button mr={2} colorScheme="brand" onClick={onClickLeft}> <Text> {"<"} </Text> </Button>
          <Text textStyle="title3" mr={2}> {idx} </Text>
          <Button colorScheme="brand" onClick={onClickRight} disabled={sortedTrovesData.length <= idx * 10}> <Text> {">"} </Text> </Button>
        </Flex>
        {
          <TokenTable
              headers={["Index", "Trove Owner", "", "Outstanding Debt", "AICR", "ICR"]}
              tooltips={["", "", "", "Trove's debt - 200", "A trove's Adjusted Individual Collateral Ratio or AICR is a ratio between collateral and debt giving additional weight to stablecoins.", ""]}
              width={6}
              display={["none", "block"]}
            >

              {sortedTrovesData.slice(idx * 10, idx * 10 + 10).map(currTroveData => (
                <Tr key={currTroveData.owner}>
                  <Td pb={0} pt={4}>
                    {currTroveData.idx}
                  </Td>

                  <Td pb={0} pt={4}>
                    {currTroveData.owner}
                  </Td>
                  {[...new Array(1)].map(_ => (
                    <Td pb={0} pt={4} />
                  ))}
                  <Td pb={0} pt={4}>
                    {getNum(currTroveData.outstandingDebt, 3)}
                  </Td>
                  <Td pb={0} pt={4}>
                    {getNum(currTroveData.aicr, 3)}
                  </Td>

                  <Td pb={0} pt={4}>
                    {getNum(currTroveData.icr, 3)}
                  </Td>
                </Tr>
              ))}

            </TokenTable>
        }

      </Box>
    </>
  );
};

export default TroveList;
