import React from "react";
import { Box, Button, Flex, Text, Progress, Spacer, useDisclosure } from "@chakra-ui/react";
import Icon from "../../../Components/Icon";
import { ConnectButton } from "../../../Components/WalletConnector";
import ConfirmCloseTroveModal from "../ConfirmCloseTroveModal";
import { calculateHealth, calculateHealthColor, calculateHealthStableTrove } from "./Trove.utils";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import tokenData from "../../../TokenData";
import { TroveMappings } from "../../../Types";
import { getNum, format, formatWithDecimals } from "../../../Utils/number";
import { useEffect } from "react";
import { useState } from "react";
import Tooltip from "../../../Components/Tooltip";
import { VC_explanation } from "../../../Utils/constants";

export type TroveProps = {
  disconnected?: boolean;
  borrowMode: "normal" | "lever" | "unlever" | "close";
  setBorrowMode: any;
};
const selector = ({ trove, tokenBalances, icr, decimals, safetyRatios, prices }: LiquityStoreState) => ({
  trove,
  tokenBalances,
  icr,
  decimals,
  safetyRatios,
  prices
});
var dataSelector = useLiquitySelector;
const Trove: React.FC<TroveProps> = ({ disconnected = false, borrowMode, setBorrowMode }) => {
  let healthRatio, totalBorrowed;
  var usdValue: number = 0;
  let vcValue: number = 0;
  //let aicr: number = 0
  let stableUSD: number = 0;
  let stableVC: number = 0;
  const { trove, tokenBalances, icr, decimals, safetyRatios, prices } = dataSelector(selector);
    // setHealthRatio(+trove.collateralRatio(underlyingPrices, false).toString());
    // setTotalBorrowed(+trove.debt["debt"]);
    healthRatio = format(icr) * 100;
    totalBorrowed = +trove.debt["debt"];
    // console.log(tokenData)
    tokenData.map(
      token => {
        // console.log(decimals[token.address].toNumber())
        token["troveBalance"] = formatWithDecimals(
        trove.collaterals[token.address],
        decimals[token.address].toNumber()
      );
    });
    tokenData.map(
      token =>
        (token["walletBalance"] = formatWithDecimals(
          tokenBalances[token.underlying == "" ? token.address : token.underlying],
          token.underlyingDecimals
        ))
    );
    tokenData.map(token => {
      let vc: number;
      let usd: number;
      const safetyRatio = format(safetyRatios[token.address]);
      const dec = decimals[token.address].toNumber();
      vc =
        format(prices[token.address]) *
        safetyRatio *
        formatWithDecimals(trove.collaterals[token.address], dec);
        vcValue += vc;
        usd =
        format(prices[token.address]) *
        formatWithDecimals(trove.collaterals[token.address], dec);
        usdValue += usd;
      if (token.isStable) {
        stableVC += vc;
        stableUSD += usd;
      }
    });
  // console.log('vcValue', vcValue)
  const {
    isOpen: isCloseTroveOpen,
    onOpen: onCloseTroveOpen,
    onClose: onCloseTroveClose
  } = useDisclosure();
  const troveHealth =
    stableVC * 1.1 > totalBorrowed && stableVC / vcValue > 0.99
      ? calculateHealthStableTrove(healthRatio)
      : calculateHealth(healthRatio);
  // console.log("111", troveHealth)
  if (trove && trove.status !== "open") {
    return (
      <Box layerStyle="base" bg="brand.800" flex={1}>
        <Text textStyle="title2">Trove</Text>
        <Text textStyle="title4" mt={2}>
          Open a Trove below to see your Trove stats!
        </Text>
      </Box>
    );
  }
  return (
    <Box layerStyle="card" flex={1}>
      <Text textStyle="title2">Trove</Text>
      <Flex flex={1} direction={["column", null, "row"]}>
        <Flex direction="column" flex={3} mr={[0, null, 5]}>
          <Text textStyle="body1" mb={3}>
            Trove Statistics
          </Text>
          {/* Collateral and total borrowed */}
          <Flex
            backgroundColor="purple.400"
            align="center"
            py={3}
            pl={2}
            pr={4}
            mb={5}
            borderRadius="2xl"
          >
            <Flex align="center">
              <Icon iconName="MoneyStack" w={12} h={5} />
              <Text textStyle="subtitle3" mx={1}>
                Total Collateral <Tooltip>{VC_explanation}</Tooltip>
              </Text>
            </Flex>
            <Spacer />
            <Text textStyle="subtitle2">
              {getNum(vcValue, 2)} RAV (${getNum(usdValue, 2)})
            </Text>
          </Flex>
          {/* Total borrowed */}
          <Flex
            backgroundColor="green.400"
            align="center"
            py={3}
            pl={2}
            pr={4}
            mb={2}
            borderRadius="2xl"
          >
            <Flex align="center">
              <Icon iconName="Bank" w={12} h={5} />
              <Text textStyle="subtitle3" mx={1}>
                Total Borrowed
              </Text>
            </Flex>
            <Spacer />
            <Text textStyle="subtitle2">{getNum(totalBorrowed)} YUSD</Text>
          </Flex>
        </Flex>
        {/* Trove health */}
        <Flex direction="column" flex={2} mt={[6, null, 0]} ml={[0, null, 5]}>
          <Text textStyle="body1" mb={3}>
            Trove Safety Rating: {troveHealth.toFixed(3)}{" "}
            <Tooltip>
              {" "}
              Score from 0 to 100 that helps trove owners to understand how safe from liquidation
              their trove is. Learn more from docs.{" "}
            </Tooltip>
          </Text>
          <Flex direction="column" mb={3}>
            <Flex gap={2} w="100%" align="center" mt={2} mb={7}>
              <Text textStyle="body3" whiteSpace="nowrap">
                0
              </Text>
              <Progress
                value={troveHealth}
                w="100%"
                colorScheme={calculateHealthColor(troveHealth)}
                bg="brand.900"
                borderRadius="infinity"
              />
              <Text textStyle="body3" whiteSpace="nowrap">
                100
              </Text>
            </Flex>
            <Flex gap={4} mb={1}>
              <Text textStyle="subtitle4" fontSize="2xl">
                Collateral Ratio:{" "}
                {trove ? `${parseFloat(icr.mul(100).toString()).toFixed(3)}% ` : "N/A "}
                <Tooltip>Ratio between Trove RAV and YUSD Debt</Tooltip>
              </Text>
            </Flex>
            {/* <Flex gap={4}>
              <Text textStyle="body1">
                Stable Adjusted Collateral Ratio:{" "}
                {trove ? `${aicr.toFixed(2)}% ` : "N/A"}
                <Tooltip>{"Stable Adjusted Collateral Ratio is Collateral Ratio that gives more weights(1.55) to stable coins in your trove. This ratio should give you a sense of how safe your trove is."}</Tooltip>
              </Text>
            </Flex> */}
          </Flex>
        </Flex>
        <Flex direction="column" flex={1} mt={[6, null, 0]} ml={[0, null, 10]}>
          <Text textStyle="body1" mb={3}>
            Trove Options
          </Text>
          {disconnected ? (
            <ConnectButton w="100%" />
          ) : (
            <>
              <Button w="100%" variant="secondary" onClick={onCloseTroveOpen}>
                Close Trove
              </Button>
              <ConfirmCloseTroveModal isOpen={isCloseTroveOpen} onClose={onCloseTroveClose} />
              {/* {borrowMode === "close" ? (
                <Button w="100%" variant="secondary" onClick={() => setBorrowMode("normal")} mt={4}>
                  Adjust Trove
                </Button>
              ) : (
                <Button w="100%" variant="secondary" onClick={() => setBorrowMode("close")} mt={4}>
                  Close Trove Auto-Sell
                </Button>
              )} */}
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
export default Trove;