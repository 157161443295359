const sleep = require("ko-sleep");

const Web3 = require("web3")

export const setupWeb3 = async () => {

    const avax_endpoints = [

      "https://api.avax.network/ext/bc/C/rpc"

    ]

    let avax_web3

    // Run through three provided avax endpoints until a connection is established and a valid web3 object is returned

    while (true) {

      for (let i = 0; i < avax_endpoints.length; i++) {

        avax_web3 = await new Web3(new Web3.providers.HttpProvider(avax_endpoints[i]))

        if (avax_web3.currentProvider) break

        await sleep(100)

      }

      if (avax_web3.currentProvider) break

    }
    // console.log('web3 provider successfully fetched')
    return {
      avax_web3
    }

  }

export default setupWeb3