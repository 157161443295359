import tokenData, { tokenDataMappingA, tokenDataMappingT } from "../../../TokenData";

export const getTotalCollateralValue = async (tokens: string[], amounts: number[], vaultData: any) => {
    let totalValue = 0;
    // console.log('gettotal')
    for (var i = 0; i < tokens.length; i++) {
      const tokenInfo = tokenData.find(t => (t['address'].toUpperCase() == tokens[i].toUpperCase()))
      // console.log(tokens[i], tokenInfo)
      const underlyingDecimals = tokenInfo!['underlyingDecimals'];
      let tokenName = tokenInfo!['token']
      const price = vaultData['price'][tokenName.replace('-', '').replace(' ', '')]
      const value = price * amounts[i] / 10 ** 18
      // console.log('prices', tokenName, price, amounts[i], value)
      totalValue += value
    }
    return totalValue
  }

export default getTotalCollateralValue