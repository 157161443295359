import React, {useEffect, useState} from "react";
import { NumberInput, Link, Box, Flex, Circle, Text, Divider, Collapse, IconButton, Stack, Center, Tooltip as ChakraTooltip, VStack, toVarReference, calc } from "@chakra-ui/react";
import { Icon, Loader } from "../../../Components"
import tokenData, { tokenDataMappingA } from "../../../TokenData";
import {getNum } from "../../../Utils/number"
import CoinAmount from "../../../Components/CoinAmount/CoinAmount"
import CurrencyConverter from "../../../Components/CurrencyConverter";
import { LiquityStoreState } from "@liquity/lib-base";
import convertVaultAmount from "../../../Screens/TroveHistory/utils/convertVaultAmount";

export type TroveHistoryDayProps = {
  day: any
  troveData: any
  userID: any
};


const selector = ({ trove, prices, tokenBalances, safetyRatios, decimals, underlyingPerReceiptRatios, receiptPerUnderlyingRatios, total }: LiquityStoreState) => ({
  trove,
  prices,
  tokenBalances,
  safetyRatios,
  decimals, 
  underlyingPerReceiptRatios, receiptPerUnderlyingRatios,
  total
});


const operationMapping = new Map<String, String>([
  ['adjustTrove', 'Adjust Trove'],
  ['openTrove', 'Open Trove'],
  ['closeTrove', 'Close Trove'],
  ['liquidateInNormalMode', 'Liquidated'],
  ['redeemCollateral', 'Partially Redeemed']
]);


const TroveHistoryDay: React.FC<TroveHistoryDayProps> = (props) => {

  const date = props.day

  const troveData = props.troveData

  const [show, setShow] = React.useState(true);
  const handleToggle = () => setShow(!show);


  const getTokenName = (token: string) => {
    return tokenData.find(t => (t['address'].toUpperCase() == token.toUpperCase()))!['token']
  }

  const getTokenAddress = (token: string) => {
    return tokenData.find(t => (t['address'].toUpperCase() == token.toUpperCase()))!['address']
  } 

  const mapTokensAndAmounts = (tokens: string[], amounts: number[]) => {
    const result = []
    for (let i = 0; i < tokens.length; i++) {
      if (amounts[i] > 0) { 
        result.push([getTokenName(tokens[i]), amounts[i]])
      }
    }
    return result

  }

  const UpdatedCoinAmount = (isAmountsIn: boolean, token: string, amount: number, vaultData: any, operation: string, color: string, sign: string) => {
    
    let tokenName = token
    if (tokenName == 'AVAX-USDC JLP') {
      tokenName = 'AVAXUSDCJLP'
    } else if (tokenName == 'WETH-WAVAX JLP') {
      tokenName = 'WETHWAVAXJLP'
    }

    const priceMap = vaultData['price']
    

    const isRedeem = operation == 'redeemCollateral'

    // console.log('operation', operation, isRedeem)
    // console.log('pricemap', priceMapping, tokenName)


    const tokenInfo = tokenData.find(t=> (t['token'] == token))
    const underlyingDecimals = tokenInfo!['underlyingDecimals'];
    
    let price = priceMap[tokenName]

    // if (tokenName == 'WETH') {
    //   console.log('WETH', vaultData, price)
    // }

    // if (tokenName == 'aUSDC') {
    //   price = priceMap['USDC']
    // } 
    // if (tokenName == 'aWAVAX') {
    //   price = priceMap['WAVAX']
    // }
    // if (tokenName == 'aWETH') {
    //   price = priceMap['WETH']
    // }
    // if (tokenName == 'aUSDT') {
    //   price = priceMap['USDT']
    // }
    // if (tokenName == 'aDAI') {
    //   price = priceMap['DAI']
    // }


    let realAmount = amount / 10 ** 18 

    let displayAmount = amount / 10 ** 18
    
    if (isAmountsIn) {
      realAmount = amount / 10 ** underlyingDecimals
      displayAmount = amount/ 10 ** underlyingDecimals
    }
    else if (tokenInfo!['isVault']) { 
      realAmount = amount / 10 ** 18
      displayAmount = amount / 10 ** underlyingDecimals * vaultData['underlyingPerReceipt'][tokenName] 
    } 
    // else if (tokenInfo!['isVault']) {
    //   realAmount = amount / 10 ** underlyingDecimals * vaultData['underlyingPerReceipt'][tokenName] 
    //   displayAmount = amount / 10 ** underlyingDecimals * vaultData['underlyingPerReceipt'][tokenName]
    // }
    return (
      <Flex>
        {<Icon iconName={token} h={5} w={5} mr={3} />}
        {sign && <Text textStyle="subtitle3" fontWeight="normal" maxWidth={300} color={color}> {sign} </Text>}
        <Text textStyle="subtitle3" color={color} textAlign={["right", "left"]}>
          {(displayAmount !== 0 && displayAmount < 0.001 ? "< 0.001" : getNum(displayAmount, 3)) + " " + token}
          {<CurrencyConverter token={token} value={realAmount} currency={"USD"} price={price}/>}
        </Text>
      </Flex>
    )
  }

    return troveData.slice(0,1).map(({ operation, collsIn, transaction, timestamp, amountsIn, collsOut, amountsOut, tokens, amounts, YUSDchange, isDebtIncrease, currentICR, totalValue, debt, vaultData }: any) => (
      <Box>
        <Center>
          <VStack align='stretch' justifyContent="center" mb={7}>
            {/* FIRST ACTION FOR THE DAY */}
            <Flex>
              {/* DATE */}
              <Flex justifyContent="left" mt={1}>
                <Circle size='20px' bg='#2f7df7' color='brand.300' mt={1.5} ml={-1.5}/>
                {/* ACTION NAME (ie Adjusted Trove, Opened Trove, etc) */}
                <Text textStyle="title4" ml={4}> {date} </Text>
              </Flex> 
              <Flex ml={110}>
                {show 
                  ? <IconButton aria-label='Expand Stake LP' size={'sm'} ml={3} onClick={handleToggle} colorScheme="brand" isRound={true} icon={<Icon style={{ transform: "rotate(180deg)" }} iconName="CollapseIcon" />} />
                  : <IconButton aria-label='Expand Stake LP' size={'sm'} ml={3} onClick={handleToggle} colorScheme="brand" isRound={true} icon={<Icon iconName="CollapseIcon" />} />
                }
              </Flex>
            </Flex>
                <Flex justifyContent="left" mt={1}>
                      <Circle size='10px' bg='#2f7df7' color='brand.300' mt={2.5} ml={-0.1}/>
                      {operation == 'redeemCollateral' && debt == 0
                      ? <Text textStyle="title4" ml={4}> Closed By Redemption </Text>
                      : <Text textStyle="title4" ml={4}> {operationMapping.get(operation)} </Text>}
    
                  </Flex> 
            <Flex>              
              <Flex justifyContent="left" mt={1}>
                <Stack direction='row'>
                  <Divider width={2} orientation='vertical' mr={4}/>
                  <Collapse in={show}>
                    <>
                      {/* Change based upon ACTION (ie Borrowed, Collateral, Total debt with Fees, Repaid, etc.) */}
                      
                      <Flex>
                      {/* <Text textStyle="subtitle3" mr={2}>BlockNum: {blockNum}</Text> */}
                      </Flex>
                      
                      <Flex>
                        <Text textStyle="subtitle3" mr={2}>Collateral Changes</Text>
                      </Flex>

                      {operation == 'openTrove' && (
                        <Flex>
                            <Text textStyle="subtitle3" fontWeight="normal" maxWidth={300} color="lightgreen">{mapTokensAndAmounts(tokens, amounts).map(([token, amount]) => 
                            <Flex>
                            {UpdatedCoinAmount(false, String(token), Number(amount), vaultData, operation, "green.400", "+")} 
                          </Flex>
                      )}</Text>
                        </Flex>
                      )}

                        <Flex>
                          <Text textStyle="subtitle3" fontWeight="normal" maxWidth={300} color="lightgreen">{mapTokensAndAmounts(collsIn, amountsIn).map(([token, amount]) => 
                          <Flex>
                          {UpdatedCoinAmount(true, String(token), Number(amount), vaultData, operation, "green.400", "+")} 
                        </Flex>     
                      )}</Text>
                          </Flex>
                          <Flex>
                          <Text textStyle="subtitle3" fontWeight="normal" white-space = "pre-line" maxWidth={300} color="red">{mapTokensAndAmounts(collsOut, amountsOut).map(([token, amount]) => 
                          <Flex>
                              {UpdatedCoinAmount(false, String(token), Number(amount), vaultData, operation, "red.400", "-")}
                            </Flex>
                          )} 
                          </Text>
                          </Flex>
                          <Flex>
                          {amounts.length > 0 && (
                            <div>
                            <Text textStyle="subtitle3" mr={2}>Final Trove State</Text>
                            <Flex>
                            <Text textStyle="subtitle3" fontWeight="normal" maxWidth={300}>
                              {mapTokensAndAmounts(tokens, amounts).map(([token, amount]) => 
                            <Flex>
                              {UpdatedCoinAmount(false, String(token), Number(amount), vaultData, operation, "yellow.400", "")} 
                            </Flex>
                          )}</Text>
                          </Flex>
                            </div>   
                            )}
                          </Flex>
                          
                         
                      
                                
                        
                      <Flex>
                        {YUSDchange > 0 && (
                        <Flex>
                          <Text textStyle="subtitle3" mr={2}>Debt Change:</Text>
                          {isDebtIncrease
                          ? <Text textStyle="subtitle3" fontWeight="normal" maxWidth={300}> {'- '} </Text>
                          : <Text textStyle="subtitle3" fontWeight="normal" maxWidth={300}> {'+ '} </Text>}
                          <Text textStyle="subtitle3" mr={2}>{getNum(YUSDchange / 10 ** 18, 2)} YUSD</Text>
                        </Flex>
  
                        )}        
                      </Flex> 

                      {/* <Flex>
                        <Text textStyle="subtitle3" mr={2}>Total Value: </Text>
                        <Text textStyle="subtitle3" fontWeight="normal">{getNum(totalValue, 2) + ' USD'} </Text>
                      </Flex>             */}

                      <Flex>
                        <Text textStyle="subtitle3" mr={2}>Debt: </Text>
                        <Text textStyle="subtitle3" fontWeight="normal">{getNum(debt * (10 ** -18), 2) + ' YUSD'} </Text>
                      </Flex>  
                      <Flex>
                        <Text textStyle="subtitle3" mr={2}>ICR: </Text>
                        {/* TODO: Change this to web3 package calls and get the right value from the past blockNum */}
                        <Text textStyle="subtitle3" fontWeight="normal">{getNum(currentICR * (10 ** -16), 2) + '%'}</Text>
                      </Flex>                
                      <Flex>
                        <Text textStyle="subtitle3" mr={2}>Time: </Text>
                        <Text textStyle="subtitle3" fontWeight="normal">{new Date(timestamp * 1000).toLocaleTimeString() + ' (' + Intl.DateTimeFormat().resolvedOptions().timeZone + ')'}</Text>
                      </Flex>
                      <Flex mb={4}>
                        <Link color="white" textDecorationLine="underline" textStyle="subtitle3" href={'https://snowtrace.io/tx/' + transaction} isExternal>
                          SnowTrace Link
                        </Link>
                      </Flex>
                  
                    </>
                  </Collapse>
                </Stack>
              </Flex>
            </Flex>

            {/* Check if there exists MORE THAN ONE ACTION in the same day */}
            {troveData.length > 1 && 
              (troveData.slice(1).map(({ operation, collsIn, transaction, timestamp, amountsIn, blockNum, collsOut, amountsOut, tokens, amounts, currentICR, YUSDchange, isDebtIncrease, totalValue, debt, tokenPriceMap }: any) => (
                <>
                  <Flex justifyContent="left" mt={1}>
                      <Circle size='10px' bg='#2f7df7' color='brand.300' mt={2.5} ml={-0.1}/>
                      {operation == 'redeemCollateral' && debt == 0
                      ? <Text textStyle="title4" ml={4}> Closed By Redemption </Text>
                      : <Text textStyle="title4" ml={4}> {operationMapping.get(operation)} </Text>}
    
                  </Flex> 
                    <Flex>              
                      <Flex justifyContent="left" mt={1}>
                        <Stack direction='row'>
                          <Divider width={2} orientation='vertical' mr={4}/>
                          <Collapse in={show}>
                            <>
                              {/* Change based upon ACTION (ie Borrowed, Collateral, Total debt with Fees, Repaid, etc.) */}
                              
                              <Flex>
                              {/* <Text textStyle="subtitle3" mr={2}>BlockNum: {blockNum}</Text> */}
                              </Flex>
                              
                              
                              <Flex>
                                <Text textStyle="subtitle3" mr={2}>Collateral Changes</Text>
                              </Flex>

                              {operation == 'openTrove' && (
                                <Flex>
                                    <Text textStyle="subtitle3" fontWeight="normal" maxWidth={300} color="lightgreen">{mapTokensAndAmounts(tokens, amounts).map(([token, amount]) => 
                                    <Flex>
                                    {UpdatedCoinAmount(false, String(token), Number(amount), vaultData, operation, "green.400", "+")} 
                                  </Flex>
                              )}</Text>
                                </Flex>
                              )}

                                <Flex>
                                  <Text textStyle="subtitle3" fontWeight="normal" maxWidth={300} color="lightgreen">{mapTokensAndAmounts(collsIn, amountsIn).map(([token, amount]) => 
                                  <Flex>
                                  {UpdatedCoinAmount(true, String(token), Number(amount), vaultData, operation, "green.400", "+")} 
                                </Flex>     
                              )}</Text>
                                  </Flex>
                                  <Flex>
                                  <Text textStyle="subtitle3" fontWeight="normal" white-space = "pre-line" maxWidth={300} color="red">{mapTokensAndAmounts(collsOut, amountsOut).map(([token, amount]) => 
                                  
                                  <Flex>
                                      {UpdatedCoinAmount(false, String(token), Number(amount), vaultData, operation, "red.400", "-")}
                                    </Flex>
                                  )} 
                                  </Text>
                                  </Flex>
                                  <Flex>
                          {amounts.length > 0 && (
                            <div>
                            <Text textStyle="subtitle3" mr={2}>Final Trove State</Text>
                            <Flex>
                            <Text textStyle="subtitle3" fontWeight="normal" maxWidth={300} color="purple">
                              {mapTokensAndAmounts(tokens, amounts).map(([token, amount]) => 
                            <Flex>
                              {UpdatedCoinAmount(false, String(token), Number(amount), vaultData, operation, "yellow.400", "")} 
                            </Flex>
                          )}</Text>
                          </Flex>
                            </div>   
                            )}
                          </Flex>
                              
            
                        <Flex>
                          {YUSDchange > 0 && (
                          <Flex>
                            <Text textStyle="subtitle3" mr={2}>Debt Change:</Text>
                            {isDebtIncrease
                            ? <Text textStyle="subtitle3" fontWeight="normal" maxWidth={300}> {'- '} </Text>
                            : <Text textStyle="subtitle3" fontWeight="normal" maxWidth={300}> {'+ '} </Text>}
                            <Text textStyle="subtitle3" mr={2}>{getNum(YUSDchange / 10 ** 18, 2)} YUSD</Text>
                          </Flex>
    
                          )}        
                        </Flex>     

                          {/* <Flex>
                            <Text textStyle="subtitle3" mr={2}>Total Value: </Text>
                            <Text textStyle="subtitle3" fontWeight="normal">{getNum(totalValue, 2) + ' USD'} </Text>
                          </Flex>          */}

                              <Flex>
                                <Text textStyle="subtitle3" mr={2}>Debt: </Text>
                                <Text textStyle="subtitle3" fontWeight="normal">{getNum(debt * (10 ** -18), 2) + ' YUSD'} </Text>
                              </Flex>  
                              <Flex>
                                <Text textStyle="subtitle3" mr={2}>ICR: </Text>
                                {/* TODO: Change this to web3 package calls and get the right value from the past blockNum */}
                                <Text textStyle="subtitle3" fontWeight="normal">{getNum(currentICR * (10 ** -16), 2) + '%'}</Text>
                              </Flex>                
                              <Flex>
                                <Text textStyle="subtitle3" mr={2}>Time: </Text>
                                <Text textStyle="subtitle3" fontWeight="normal">{new Date(timestamp * 1000).toLocaleTimeString() + ' (' + Intl.DateTimeFormat().resolvedOptions().timeZone + ')'}</Text>
                              </Flex>
                              <Flex mb={4}>
                                <Link color="white" textDecorationLine="underline" textStyle="subtitle3" href={'https://snowtrace.io/tx/' + transaction} isExternal>
                                  SnowTrace Link
                                </Link>
                              </Flex>
                          
                            </>
                          </Collapse>
                        </Stack>
                      </Flex>
                    </Flex>
                </>
              )))
            }
          </VStack>
        </Center>
      </Box>
    ));
};

export default TroveHistoryDay;
