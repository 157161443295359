import React, {useEffect, useState} from "react";
import { Header } from "../../Components";
import { Box, Flex, Button, Spacer, Text, Divider, Collapse, Input, Stack, Center, Tooltip as ChakraTooltip, VStack } from "@chakra-ui/react";
import Tooltip from "../../Components/Tooltip";
import { Loader } from "../../Components"
import getRedemptionData from "./utils/getRedemptionData"
import getVaultData from "./utils/getVaultData";
import setupWeb3 from "./utils/setupWeb3";
import getCurrentICR from "./utils/getCurrentICR";
import getTroveHistory from "./utils/getTroveHistory";
import getTotalCollateralValue from "./utils/getTotalCollateralValue";
import { getCalculatedTroveHistoryDay } from "./utils/getCalculatedTroveHistoryDay";
import TroveHistoryDay from "../../PageComponents/TroveHistoryPage"
import tokenData, { tokenDataMappingA, tokenDataMappingT } from "../../TokenData";
import calculateTokenAmounts from "./utils/calculateTokenAmounts";
import {NumberInput, NumberInputField, useDisclosure} from '@chakra-ui/react'
import { useLiquity } from "../../Hooks/LiquityContext";

const { ApolloClient, InMemoryCache, gql, HttpLink } = require('@apollo/client/core')

export type TroveHistoryData = {
  tokens: string[];
  amounts: number[];
  collsIn: string[];
  amountsIn: number[];
  collsOut: string[];
  amountsOut: number[];
  currentICR: number;
  YUSDchange: number;
  blockNum: number;
  totalValue: number;
  debt: number;
  isDebtIncrease: boolean;
  operation: string;
  transaction: string;
  timestamp: number;
  vaultData: any;
}

export type DayHistory = {
  day: String,
  data: Array<TroveHistoryData>
}


// console.log('map T address', tokenDataMappingT['WAVAX'].address)


const TroveHistory: React.FC = () => {

  const [web3, setWeb3Provider] = useState<any>(null)

  useEffect(() => {
    const getWeb3Provider = async() => {
      // console.log('setting web3')
      setWeb3Provider((await setupWeb3()).avax_web3)
    }
    getWeb3Provider()
  }, [])  

  const { account } = useLiquity();

  // const userID = "0x7cdb2a7107b49230197bb620ef23f99ac3574133"

  const [idx, setIdx] = useState<number>(0)

  const [maxIdx, setMaxIdx] = useState<number>(0)
  
  const [pageLength, setPageLength] = useState<number>(-1)

  const dataLengthPerPage = 10

  // const rawHistory = new Map<number, Array<[String, Array<TroveHistoryData>]>>()

  const [loading, setLoading] = useState<boolean>(true)

  const [fetchedHistory, setFetchedHistory] = useState<Array<any>>([])

  const [indexedHistory, setIndexedHistory] = useState<Map<number, Array<[String, Array<TroveHistoryData>]>>>(new Map<number, Array<[String, Array<TroveHistoryData>]>>())

  const [cachedHistory, setCachedHistory] = useState<Map<number, Array<DayHistory>>>(new Map<number, Array<DayHistory>>())

  // Get Full Trove History

  const getIndexedTroveHistory = async (userID: string) => {

    setCachedHistory(new Map<number, Array<DayHistory>>())

    const array : any = await getTroveHistory(userID)

    setFetchedHistory(array)

    // console.log('got array', array)

    return array
  }

  const onClickLeft = () => {
    if (idx > 0) {
      setIdx(idx - 1)
      setIdxInput(idx)
    }
  }

  const onClickRight = () => {
    if (idx + 1 <  pageLength) {
      setIdx(idx + 1)
      setIdxInput(idx + 2)
      if (idx + 1 > maxIdx) {
        setMaxIdx(idx + 1)
      }
    }

  }

  const [userID, setuserID] = useState<string>(account)

  useEffect(() => {

    const getNextHistoryBatch = async() => {

      setLoading(true)
      
      const getSetFullHistory = async () => {
        if (loading) {
          const fetchedHistoryResult = await getIndexedTroveHistory(userID)
          return fetchedHistoryResult
        }    
        return fetchedHistory
      }

      const getIndexedHistory = async () => {
        if (indexedHistory.size > 0) {
          return indexedHistory
        } 
        // console.log('fetching new data')
        const rawHistory = new Map<number, Array<[String, Array<TroveHistoryData>]>>()
        const fullHistory = await getSetFullHistory()
        var index = 0
        var dataCount = 0
        var outerBatch : Array<[String, Array<TroveHistoryData>]> = []
        var innerBatch : any[] = []
        for (let i = 0; i < fullHistory.length; i++) {
          const date = fullHistory[i][0]
          const dayHistory = fullHistory[i][1]
          for (let j = 0; j < dayHistory.length; j++) {
            if (dataCount >= dataLengthPerPage) {
              if (innerBatch.length > 0) {
                outerBatch.push([date, innerBatch])
              }
              rawHistory.set(index, outerBatch)
              index += 1
              innerBatch = []
              outerBatch = []
              dataCount = 0
            }
            innerBatch.push(dayHistory[j])
            dataCount += 1
          }
          outerBatch.push([date, innerBatch])
          innerBatch = []
        }
        if (outerBatch.length > 0) {
          rawHistory.set(index, outerBatch)
        }
        // console.log('setting page length', rawHistory.size)
        setPageLength(rawHistory.size)  
        setIndexedHistory(rawHistory) 
        return rawHistory
      }
    
      const rawHistory = await getIndexedHistory()

      const currentBatch : Array<DayHistory> = new Array<DayHistory>()

      if (rawHistory.size == 0) {
        console.log('No data')
        setLoading(false)
        return
      }

      const rawBatch :any = rawHistory.get(idx)
      for (let j = 0; j < rawBatch.length; j ++) {
        const dayEntry = rawBatch[j]
        // console.log('day Entry', dayEntry)
        const day = dayEntry[0]
        const dayHistory = dayEntry[1]
        const calculatedDayArray : Array<TroveHistoryData> = []
        for (let j = 0; j < dayHistory.length; j ++) {
          const calculatedHistoryData = await getCalculatedTroveHistoryDay(web3, userID, dayHistory[j])
          // console.log('calculated day', calculatedHistoryData)
          calculatedDayArray.push(calculatedHistoryData)
        }
        const calculatedDayHistory : DayHistory = {
          day: day,
          data: calculatedDayArray
        }
        currentBatch.push(calculatedDayHistory)
      }
      
      // console.log('updating cachedHistory', cachedHistory.set(idx, currentBatch))

      // console.log('should display', cachedHistory.get(idx))
      
      setCachedHistory(cachedHistory.set(idx, currentBatch))  

      setLoading(false)
    }

    // console.log(loading, cachedHistory.has(idx))

    if (!cachedHistory.has(idx) || loading) {

      // console.log('fetching data', cachedHistory)

      getNextHistoryBatch().then(() => setLoading(false))

    }

  }, [idx, userID])

  const [idxInput, setIdxInput] = useState<number>(1)
  

  const handleChange = (idxInput: any) => {
    setIdxInput(Number(idxInput))}

  const onClickGo = () => {
    // console.log('click Go', idxInput - 1, pageLength)
    if (idxInput - 1 < pageLength) {
      setIdx(idxInput - 1)
      if (idxInput - 1 > maxIdx) {
        // console.log('click go max', idxInput - 1)
        setMaxIdx(idxInput - 1)
      }
    }
  }

  const [idInput, setIdInput] = useState<string>('')

  const handleIdChange = (event: any) => {
    // console.log('id change')
    setIdInput(event.target.value)
  }

  const onClickSearch = () => {
    // console.log('search')
    setLoading(true)
    if (idInput == '') {
      setuserID(account)
    } else {
      indexedHistory.clear()
      // console.log('searching!')
      setuserID(idInput)
    }
    setIdx(0)
    setIdxInput(1)
  }

  const {
    isOpen: isPortfolioOpen,
    onOpen: onPortfolioOpen,
    onClose: onPortfolioClose
  } = useDisclosure();

  const {
    isOpen: isFeeGraphOpen,
    onOpen: onFeeGraphOpen,
    onClose: onFeeGraphClose
  } = useDisclosure();

  const onPortfolioClick = async () => {
    onPortfolioOpen()
  }

  const onFeeGraphClick = async () => {
    onFeeGraphOpen()
  }

  // console.log('cachedHistory', cachedHistory)

  return (
    <>
    <Box>  
      <Header title="calculator.png" />
      <Box layerStyle="card" flex={1} mt={6}>
      <Flex>
        <Text textStyle="title2" color="white" mb={5} justifyContent="center">
          Trove History
        </Text> 
        </Flex>

        <Flex ml={5} mb={5}><Input textStyle="title4" placeholder={'Account: '.concat(account)} value ={idInput} onChange={handleIdChange}/> <Button ml={2} mr={2} colorScheme="brand" width='60px' size='s' onClick={onClickSearch} disabled= {pageLength > 0 && !cachedHistory.has(idx)}> <Text textStyle="subtitle3"> {"Search"} </Text> </Button></Flex>
        {pageLength == -1
        ? <div></div>
      : <div>
          {
          pageLength == 0 
            ? (<Flex flexDirection="column" alignItems="center" gap={4}>
            <Text textStyle="title4">No Trove History </Text>
          </Flex>)
          : <div> 
          <Flex mb={3}>
            <Text textStyle="title5" mr={2}> Page </Text>
            <NumberInput size='s' maxW='40px' mr='2' value={idxInput} min={1} max={pageLength} onChange={handleChange}> <NumberInputField textAlign="center" textStyle="title5"></NumberInputField></NumberInput> 
            <Text textStyle="title5" mr={2}> of  {pageLength} </Text>
            <Button mr={2} colorScheme="brand" width='60px' size='s' onClick={onClickGo} disabled= {!loading && !cachedHistory.has(idx)}> <Text> {"Go!"} </Text> </Button>
          </Flex>
          <Flex flex={2}>
            <Spacer />
            <Button mr={2} colorScheme="brand" onClick={onClickLeft} disabled = {idx == 0 || !cachedHistory.has(idx)}> <Text> {"<"} </Text> </Button>
            <Button colorScheme="brand" onClick={onClickRight} disabled= {!cachedHistory.has(idx) || idx + 1>= pageLength}> <Text> {">"} </Text> </Button>
          </Flex>

          {
          (pageLength > 0 && !cachedHistory.has(idx))
            ? 
            <Flex flexDirection="column" alignItems="center" gap={4}>
              <Loader />
              <Text textStyle="title4">Fetching Data </Text>
            </Flex>
          : <div>
          {cachedHistory.get(idx)!.map((dayHistory) => (
              <TroveHistoryDay day={dayHistory.day} troveData={dayHistory.data} userID={userID} />
            ))}
        </div>
          }
          </div>
          
          }         
        </div>}     

          <Flex>
          <Button mr={2} colorScheme="brand" width='100px' size='md' onClick={onClickLeft} disabled = {idx == 0 || !cachedHistory.has(idx)}> <Text textStyle="title5"> {"Prev page"} </Text> </Button>
            <Spacer />
            <Button mr={2} colorScheme="brand" width='100px' size='md' onClick={onClickRight} disabled= {!cachedHistory.has(idx) || idx + 1>= pageLength}> <Text textStyle="title5"> {"Next Page"} </Text> </Button>
          </Flex>
      </Box> 
    </Box>
    </>
  );
};

export default TroveHistory;
