import React, { useEffect, useState, useContext } from "react"
import { TroveData } from "../Types";
export interface ProtocolContextData {
  sortedTrovesData: TroveData[]
}

const PoolDataContext = React.createContext({} as ProtocolContextData);


export const ProtocolDataProvider: React.FC = ({ children }) => {



  const [sortedTrovesData, setSortedTrovesData] = useState<TroveData[]>([])



  useEffect(() => {
    const url = "https://api.yeti.finance/v1/SortedTroves/SortedTrovesData";
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: "GET", mode: 'cors'
        })
        const data = await response.json()
        
        setSortedTrovesData(data)

      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);
                          

  return (
    <PoolDataContext.Provider
      value={{
        sortedTrovesData
      }}
    >
      {children}
    </PoolDataContext.Provider>
  );
}

export const useProtocolDataContext = () => useContext(PoolDataContext);
