
const { ApolloClient, InMemoryCache, gql, HttpLink } = require('@apollo/client/core')

export const getRedemptionData = async (transactionID: string) => {

    const redemptionQuery = `
    query {
      newRedemptions(where: {transaction: "${transactionID}"}) {
        blockNum
        YUSDPaid
        attemptedYUSDAmount
        tokens
        amounts
        transaction
        timestamp
      }
    }
    `

    const client = new ApolloClient({
      uri: "https://api.thegraph.com/subgraphs/name/0xcano/yeti",
      cache: new InMemoryCache()
    });

    const result = await client
      .query({
        query: gql(redemptionQuery)
      })
      .then((data: any) => data)
      .catch((err: any) => {
        console.log('Error fetching redemption data: ', err)
      })

    // console.log('redemption info', result)

    return result.data.newRedemptions[0]
  }

  export default getRedemptionData