import getVaultData from "./getVaultData"
import calculateTokenAmounts from "./calculateTokenAmounts"
import { TroveHistoryData } from "../TroveHistory"
import getTotalCollateralValue from "./getTotalCollateralValue"
import getCurrentICR from "./getCurrentICR"
import getRedemptionData from "./getRedemptionData"

export const getCalculatedTroveHistoryDay = async (web3: any, userID: string, troveDataEntry: any) => {

    const vaultData = await getVaultData(troveDataEntry.blockNum)

    // Change this to calculateAmounts = amounts * their underlyingPerReceipt
    
    const calculatedTroveHistoryData: TroveHistoryData = {
      amounts : troveDataEntry.amounts,
      amountsIn : troveDataEntry.amountsIn,
      amountsOut : troveDataEntry.amountsOut,
      vaultData: vaultData,
      YUSDchange : troveDataEntry.YUSDchange,
      blockNum : troveDataEntry.blockNum,
      currentICR : troveDataEntry.currentICR,
      totalValue: await getTotalCollateralValue(troveDataEntry.tokens, troveDataEntry.amounts, vaultData),
      debt : troveDataEntry.debt,
      tokens : troveDataEntry.tokens,
      collsIn : troveDataEntry.collsIn,
      collsOut : troveDataEntry.collsOut,
      operation : troveDataEntry.operation,
      transaction : troveDataEntry.transaction,
      isDebtIncrease : troveDataEntry.isDebtIncrease,
      timestamp: troveDataEntry.timestamp
    } 
    if (calculatedTroveHistoryData.operation == 'redeemCollateral') {
      if (calculatedTroveHistoryData.debt != 0) {
          calculatedTroveHistoryData.currentICR = await getCurrentICR(web3, userID, calculatedTroveHistoryData.blockNum)
        }
        const redemptionData = await getRedemptionData(calculatedTroveHistoryData.transaction)
        calculatedTroveHistoryData.collsOut = redemptionData.tokens
        calculatedTroveHistoryData.amountsOut = redemptionData.amounts
        calculatedTroveHistoryData.YUSDchange = Number(redemptionData.attemptedYUSDAmount)
        calculatedTroveHistoryData.isDebtIncrease = true
      } 
    return calculatedTroveHistoryData
  }