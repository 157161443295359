import { Box } from "@chakra-ui/react";
import React from "react";
import { Header } from "../../Components";
import StrategiesCalculator from "../../PageComponents/StrategiesCalculator";

const Strategies: React.FC = () => {
  return (
    <Box>
      <Header title="calculator.png" />
      <Box mt={6}>
        <StrategiesCalculator />
      </Box>
    </Box>
  );
};

export default Strategies;
