import React from "react";
import { paths } from "./Navbar.constants";
import { Flex, HStack, Spacer, Stack, Box } from "@chakra-ui/react";
import Link from "./Link";
import UserDetails from "./UserDetails";
import { ConnectButton } from "../WalletConnector";
import { useMediaQuery, Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react";

export type NavbarProps = {
  walletConnected: boolean;
  dashboardVisible: boolean;
  snow?: 0 | 1;
  setSnow?: any;
};

const Navbar: React.FC<NavbarProps> = ({ dashboardVisible, walletConnected, snow, setSnow }) => {
  const [isMobile] = useMediaQuery("(max-width: 1000px)");
  return (
    <Flex mb={8} direction={["column", null, null, "row"]} align="center" justify="space-between">
      <>
        <HStack spacing={1}>
          {dashboardVisible && <Link key={"/dashboard"} to="/" label="Dashboard" />}

          {paths.slice(1).map(({ path, label }) => (
            <div key={`${path} ${label}`}>
              {path !== "/more" ? <Link key={path} to={path} label={label} /> : 
                <Menu>
                  <MenuButton
                    key={"more"}
                    as={Button}
                    rounded={'full'}
                    variant={'link'}
                    cursor={'pointer'}
                    minW={0}>
                    <Link key={path} to={path} label={label} />
                  </MenuButton>
                  <MenuList key={"list"}>
                      <MenuItem key={"calculator"}><Link key={"/calculator"} to={"/calculator"} label={"Calculator"} hasRadius={false} /></MenuItem>
                      <MenuItem key={"trovehistory"}><Link key={"/trovehistory"} to={"/trovehistory"} label={"Trove History"} hasRadius={false} /></MenuItem>
                      <MenuItem key={"redemption"}><Link key={"/redemption"} to={"/redemption"} label={"Redeem"} hasRadius={false} /></MenuItem>
                  </MenuList>
                </Menu>
              }
            </div>
          ))}
        </HStack>
        {!isMobile ? (
          <Flex align="center">
            {walletConnected ? <UserDetails onChange={setSnow} /> : <ConnectButton />}
          </Flex>
        ) : (
          <>
            <Spacer />
            <Flex align="center" direction={["column", "row"]} mt={[6, null, null, 0]}>
              {walletConnected ? <UserDetails onChange={setSnow} /> : <ConnectButton />}
            </Flex>
          </>
        )}
      </>
    </Flex>
  );
};

export default Navbar;