import React, { useState, useEffect } from "react";
import { Flex, Text, Button, Spacer, useDisclosure } from "@chakra-ui/react";
import ClaimCollateralSurplus from "./ClaimCollateralSurplus";
import { useLiquitySelector } from "@liquity/lib-react";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquity } from "../../Hooks/LiquityContext";
import { useProtocolDataContext } from "../../Hooks/useProtocolDataContext";
import { format, getNum } from "../../Utils/number";
const selector = ({ trove, collateralSurplusBalance }: LiquityStoreState) => ({
  trove,
  collateralSurplusBalance
});

const LiquidationEvent: React.FC = () => {
  const { trove, collateralSurplusBalance } = useLiquitySelector(selector);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { liquity, account } = useLiquity();

  const { sortedTrovesData } = useProtocolDataContext()

  const [hasClaimableSurplus, setClaimableSurplus] = useState<boolean>(false);

  const [ isBottom10, setIsBottom10 ] = useState<boolean>(false);

  const [ troveIdx, setTroveIdx ] = useState<number>(10);

  const [redemptionBonus, setRedemptionBonus] = useState<number>(0);

  const [debtBeforeRedemption, setDebtBeforeRedemption] = useState<number>(0);

  liquity.hasClaimableCollateral(account).then(surplus => {
    setClaimableSurplus(surplus);
  });

  liquity.getRedemptionBonus(account).then(bonus => {
    setRedemptionBonus(format(bonus));
  });

  

  useEffect(() => {
    let tempDebtBeforeRedemption = 0

    for (let i = 0; i < Math.round(0.1 * sortedTrovesData.length); i++) {
 
      if (account.toLowerCase() === sortedTrovesData[i].owner.toLowerCase()) {
         setIsBottom10(true)
         setTroveIdx(i)
         setDebtBeforeRedemption(tempDebtBeforeRedemption)
         break
      }
      tempDebtBeforeRedemption += sortedTrovesData[i].outstandingDebt
    }
  }, []);

  
  
  let isDisplayed = false,
    type;
  if (trove.status === "closedByLiquidation") {
    isDisplayed = true;
    type = "liquidation";
  } else if (trove.status === "closedByRedemption") {
    isDisplayed = true;
    type = "redeem";
  } else if (
    trove.status === "open" &&
    localStorage.getItem(account + "closeYetiFinanceBanner") != "open"
  ) {
    localStorage.setItem(account + "closeYetiFinanceBanner", "open");
  }

  let openBanner;
  const [update, setUpdate] = useState<0 | 1>(0);
  if (
    localStorage.getItem(account + "closeYetiFinanceBanner") == undefined ||
    localStorage.getItem(account + "closeYetiFinanceBanner") == "open"
  ) {
    openBanner = true;
  } else if (localStorage.getItem(account + "closeYetiFinanceBanner") == "close") {
    openBanner = false;
  }
  if (!isDisplayed) {
    openBanner = false;
  }
  if (hasClaimableSurplus || redemptionBonus != 0) {
    openBanner = true;
  }
  const onSubmit = (): void => {
    localStorage.setItem(account + "closeYetiFinanceBanner", "close");

    // Force a rerender of page
    setUpdate(1);
  };

  return (
    <>
    {isBottom10 && (
        <Flex
        bg={"yellow.500"}
        align="center"
        px={16}
        py={6}
        w="100vw"
      >
        <Text fontWeight="bold" fontSize="2xl" color="brand.100">
          Friendly Warning: 
          <br/>
          Only {getNum(troveIdx / sortedTrovesData.length * 100, 0)}% of the troves have lower {" "}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://techdocs.yeti.finance/how-does-yeti-finance-work/recovery-mode#what-is-the-total-collateral-ratio"}
                style={{ outline: "none", textDecoration: "underline" }}
                  >
                    AICR 
          </a> {" "} than yours, so your trove has the potential to be {" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://techdocs.yeti.finance/how-does-yeti-finance-work/redemptions-and-yusd-price-stability#what-are-redemptions"}
              style={{ outline: "none", textDecoration: "underline" }}
                >
                  redeemed against
            </a>
          {" "}. <br/>There are {troveIdx - 1} troves and ${getNum(debtBeforeRedemption, 3)} debt in front of you before your trove get redeemed against. 
          <br/>
          Add more collateral or repay some debt to limit such risk.
                  </Text>
        <Spacer />
        <Button variant="ghost" onClick={() => {setIsBottom10(false)}}>x</Button>
        </Flex>
      )}
      {openBanner && (
        <Flex
          bg={type === "redeem" || !type ? "green.500" : "red.500"}
          align="center"
          px={16}
          py={6}
          w="100vw"
        >
          <Text fontWeight="bold" fontSize="2xl" color="brand.100">
            {type === "redeem"
              ? "You've been redeemed against "
              : type === "liquidation"
              ? "You've been liquidated "
              : hasClaimableSurplus && redemptionBonus != 0
              ? "You were liquidated and redeemed in past troves "
              : hasClaimableSurplus
              ? "You were liquidated in a past trove "
              : redemptionBonus != 0 && "Your trove was partially redeemed "}
          </Text>
          <Spacer />
          {/* <a href="">
            <Button variant="tertiary" mr={6}>
              Snowtrace Link
            </Button>
          </a> */}
          {hasClaimableSurplus && redemptionBonus != 0 ? (
            <Button variant="tertiary" onClick={onOpen}>
              Claim Collateral Surplus and Redemption Bonus
            </Button>
          ) : hasClaimableSurplus ? (
            <Button variant="tertiary" onClick={onOpen}>
              Claim Collateral Surplus
            </Button>
          ) : redemptionBonus != 0 ? (
            <Button variant="tertiary" onClick={onOpen}>
              Claim Redemption Bonus
            </Button>
          ) : (
            <Button variant="tertiary" onClick={onSubmit}>
              Close
            </Button>
          )}
          <ClaimCollateralSurplus isOpen={isOpen} onClose={onClose} />
        </Flex>
      )}
    </>
  );
};

export default LiquidationEvent;
