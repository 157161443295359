export const getVaultData = async (blockNum: any) => { 
    const vaultUrl = `https://api.yeti.finance/v1/vault/${blockNum}`
    const vaultResponse = await fetch(vaultUrl, {
      method: "GET", mode: 'cors'
    })
    // console.log('pinging api for vault data', vaultUrl)
    const json = await vaultResponse.json()
    // console.log(json['price'])
    return json
}

export default getVaultData