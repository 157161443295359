import { createIcon } from "@chakra-ui/react";

const calculator = createIcon({
  displayName: "calculator",
  viewBox: "0 10 368 368",
  path: (
    <g>
			<path d="M328,16H40C18,16,0,34,0,56v256c0,22,18,40,40,40h288c22,0,40-18,40-40V56C368,34,350,16,328,16z M352,312
				c0,13.2-10.8,24-24,24H40c-13.2,0-24-10.8-24-24V56c0-13.2,10.8-24,24-24h288c13.2,0,24,10.8,24,24V312z"/>
			<path d="M144,112h-32V80c0-4.4-3.6-8-8-8c-4.4,0-8,3.6-8,8v32H64c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h32v32c0,4.4,3.6,8,8,8
				c4.4,0,8-3.6,8-8v-32h32c4.4,0,8-3.6,8-8C152,115.6,148.4,112,144,112z"/>
			<path d="M296,112h-80c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h80c4.4,0,8-3.6,8-8C304,115.6,300.4,112,296,112z"/>
			<path d="M137.6,214c-3.2-3.2-8.4-3.2-11.2,0L104,236.8L81.6,214c-3.2-3.2-8-3.2-11.2,0s-3.2,8,0,11.2L93.2,248l-22.8,22.8
				c-3.2,3.2-3.2,8,0,11.2c1.6,1.6,3.6,2.4,5.6,2.4s4-0.8,5.6-2.4l22.4-22.8l22.4,22.8c1.6,1.6,3.6,2.4,5.6,2.4s4-0.8,5.6-2.4
				c3.2-3.2,3.2-8,0-11.2L115.2,248l22.8-22.8C140.8,222,140.8,217.2,137.6,214z"/>
			<path d="M296,256h-80c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h80c4.4,0,8-3.6,8-8C304,259.6,300.4,256,296,256z"/>
			<path d="M296,208h-80c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h80c4.4,0,8-3.6,8-8C304,211.6,300.4,208,296,208z"/>
		</g>
  )
});

export default calculator;
