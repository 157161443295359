import { createIcon } from "@chakra-ui/react";

const WAVAXUSDC = createIcon({
  displayName: "WAVAXUSDC",
  viewBox: "0 0 3600 3600",
  path: (
    <g>
      <path d="M2589 2787C3143.17 2787 3589 2341.17 3589 1787C3589 1232.83 3143.17 787 2589 787C2034.83 787 1589 1232.83 1589 1787C1589 2341.17 2034.83 2787 2589 2787Z" fill="#2775CA"/>
<path d="M2864 1945.33C2864 1799.5 2776.5 1749.5 2601.5 1728.67C2476.5 1712 2451.5 1678.67 2451.5 1620.33C2451.5 1561.99 2493.17 1524.5 2576.5 1524.5C2651.5 1524.5 2693.17 1549.5 2714 1612C2718.17 1624.5 2730.67 1632.83 2743.17 1632.83H2809.83C2826.5 1632.83 2839 1620.33 2839 1603.67V1599.5C2822.33 1507.83 2747.33 1437 2651.5 1428.67V1328.67C2651.5 1312 2639 1299.5 2618.17 1295.33H2555.67C2539 1295.33 2526.5 1307.83 2522.33 1328.67V1424.5C2397.33 1441.17 2318.17 1524.5 2318.17 1628.67C2318.17 1766.17 2401.5 1820.33 2576.5 1841.17C2693.17 1862 2730.67 1887 2730.67 1953.67C2730.67 2020.34 2672.33 2066.17 2593.17 2066.17C2484.83 2066.17 2447.33 2020.33 2434.83 1957.83C2430.67 1941.17 2418.17 1932.83 2405.67 1932.83H2334.83C2318.17 1932.83 2305.67 1945.33 2305.67 1962V1966.17C2322.33 2070.33 2389 2145.33 2526.5 2166.17V2266.17C2526.5 2282.83 2539 2295.33 2559.83 2299.5H2622.33C2639 2299.5 2651.5 2287 2655.67 2266.17V2166.17C2780.67 2145.33 2864 2057.83 2864 1945.33V1945.33Z" fill="white"/>
<path d="M2376.5 2382.83C2051.5 2266.17 1884.83 1903.67 2005.67 1582.83C2068.17 1407.83 2205.67 1274.5 2376.5 1212C2393.17 1203.67 2401.5 1191.17 2401.5 1170.33V1112C2401.5 1095.33 2393.17 1082.83 2376.5 1078.67C2372.33 1078.67 2364 1078.67 2359.83 1082.83C1964 1207.83 1747.33 1628.67 1872.33 2024.5C1947.33 2257.83 2126.5 2437 2359.83 2512C2376.5 2520.33 2393.17 2512 2397.33 2495.33C2401.5 2491.17 2401.5 2487 2401.5 2478.67V2420.33C2401.5 2407.83 2389 2391.17 2376.5 2382.83ZM2818.17 1082.83C2801.5 1074.5 2784.83 1082.83 2780.67 1099.5C2776.5 1103.67 2776.5 1107.83 2776.5 1116.17V1174.5C2776.5 1191.17 2789 1207.83 2801.5 1216.17C3126.5 1332.83 3293.17 1695.33 3172.33 2016.17C3109.83 2191.17 2972.33 2324.5 2801.5 2387C2784.83 2395.33 2776.5 2407.83 2776.5 2428.67V2487C2776.5 2503.67 2784.83 2516.17 2801.5 2520.33C2805.67 2520.33 2814 2520.33 2818.17 2516.17C3214 2391.17 3430.67 1970.33 3305.67 1574.5C3230.67 1337 3047.33 1157.83 2818.17 1082.83V1082.83Z" fill="white"/>
<path d="M1677 1147H466V2220H1677V1147Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2042 1792C2042 2341.53 1584.89 2787 1021 2787C457.117 2787 0 2341.53 0 1792C0 1242.48 457.117 797 1021 797C1584.89 797 2042 1242.48 2042 1792ZM731.678 2187.96H533.53C491.894 2187.96 471.327 2187.96 458.787 2180.14C445.242 2171.58 436.965 2157.41 435.962 2141.76C435.208 2127.35 445.493 2109.74 466.06 2074.54L955.311 1234.13C976.129 1198.44 986.663 1180.6 999.956 1174C1014.25 1166.91 1031.31 1166.91 1045.61 1174C1058.9 1180.6 1069.43 1198.44 1090.25 1234.13L1190.83 1405.23L1191.34 1406.11C1213.83 1444.39 1225.23 1463.81 1230.21 1484.18C1235.73 1506.43 1235.73 1529.89 1230.21 1552.14C1225.19 1572.67 1213.91 1592.22 1191.08 1631.09L934.09 2073.81L933.425 2074.94C910.792 2113.54 899.322 2133.11 883.425 2147.87C866.118 2164.01 845.3 2175.73 822.475 2182.34C801.656 2187.96 778.331 2187.96 731.678 2187.96ZM1232.07 2187.96H1515.99C1557.87 2187.96 1578.95 2187.96 1591.5 2179.9C1605.04 2171.35 1613.56 2156.92 1614.32 2141.28C1615.04 2127.33 1604.98 2110.41 1585.27 2077.26C1584.59 2076.13 1583.91 2074.98 1583.22 2073.81L1441 1836.72L1439.38 1834.05C1419.39 1801.11 1409.31 1784.48 1396.35 1778.05C1382.06 1770.96 1365.24 1770.96 1350.95 1778.05C1337.91 1784.65 1327.38 1802.01 1306.56 1836.96L1164.85 2074.06L1164.36 2074.88C1143.62 2109.77 1133.25 2127.21 1134 2141.52C1135 2157.17 1143.28 2171.58 1156.82 2180.14C1169.11 2187.96 1190.18 2187.96 1232.07 2187.96Z" fill="#E84142"/>
    </g>
  )
});

export default WAVAXUSDC;