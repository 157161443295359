
import { Flex } from "@chakra-ui/react";
import { RedeemCard, TroveList } from "../../PageComponents/Redemption";
import React from "react";

export type RedemptionProps = {
  disconnected?: boolean;
};

const Redemption: React.FC<RedemptionProps> = ({ disconnected = false }) => {


  
  return (
  <>
  <Flex flex={1}>
    <RedeemCard/>
  </Flex>
    
  <Flex flex={1} mt={6}>
    <TroveList/>
  </Flex>
    
    </>
  )
};

export default Redemption;
