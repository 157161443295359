import { createIcon } from "@chakra-ui/react";

const veYETI = createIcon({
  displayName: "veYETI",
  viewBox: "0 0 630 630",
  path: (
    <g>
      <defs>
    <linearGradient id="a" x1="71.45" x2="604.55" y1="253.18" y2="439.56" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ff9500" />
      <stop offset=".76" stop-color="#ff3100" />
    </linearGradient>
    <linearGradient xlinkHref="#a" id="c" x1="212.28" x2="404.1" y1="231.44" y2="231.44" />
    <linearGradient xlinkHref="#a" id="d" x1="340.7" x2="475.55" y1="287.39" y2="287.39" />
    <linearGradient xlinkHref="#a" id="e" x1="366.9" x2="492.83" y1="449.49" y2="449.49" />
    <linearGradient xlinkHref="#a" id="f" x1="198.6" x2="424" y1="491.18" y2="491.18" />
    <linearGradient xlinkHref="#a" id="g" x1="337.9" x2="389.5" y1="182.27" y2="182.27" />
    <linearGradient xlinkHref="#a" id="h" x1="281.3" x2="334.9" y1="183.57" y2="183.57" />
    <linearGradient id="b" x1="229.2" x2="239.8" y1="330.17" y2="330.17" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ff3103" />
      <stop offset=".76" stop-color="#ff3100" />
    </linearGradient>
    <linearGradient xlinkHref="#b" id="i" x1="228.7" x2="240.3" />
    <linearGradient xlinkHref="#b" id="j" x1="340.5" x2="351.1" />
    <linearGradient xlinkHref="#b" id="k" x1="340" x2="351.6" />
    <linearGradient xlinkHref="#a" id="l" x1="168.01" x2="199.3" y1="354.12" y2="354.12" />
    <linearGradient xlinkHref="#a" id="m" x1="434.64" x2="482.3" y1="342.32" y2="342.32" />
  </defs>
  <circle cx="338" cy="346.37" r="271.8" fill="#fff" stroke="url(#a)" stroke-miterlimit="10" stroke-width="21" />
  <path fill="url(#c)" d="M212.3 247.17c-.7-2 22.6-14.9 51.6-23.1 28-7.9 75.6-15.5 140.2 2.1-28.8-1.5-57.7 1.8-85.5 9.7-5.1-.3-12.2-.7-20.8-.6-50.3.3-84.7 14.2-85.5 11.9Z" />
  <path fill="url(#d)" d="M340.7 238.57c9.4-2.3 18.9-4.2 28.5-5.5 18.2-2.5 36.5-3.1 54.8-1.8 8.7 1.8 17 5.5 24.2 10.7 22.4 16.5 25.8 42.9 26.7 50 3.3 25.5-6.8 45.1-10.8 52.2.6-6.3.5-12.6-.2-18.8-.7-6.3-4.4-39.1-32.2-60.7-10.5-8-22.8-13.4-35.8-15.8-18.4-3.4-36.8-6.8-55.2-10.3Z" />
  <path fill="url(#e)" d="M437.5 381.08c9.3 4.6 36.6 19.8 49 51 3 7.6 9.5 24.7 4.5 45.2-1.7 7-5.1 19.7-17.2 29.6-7.5 6.2-16.6 10-26.3 10.9-7.2.6-15.7-.5-39.2-13-14.4-7.6-28.2-16.2-41.4-25.7 8.3 2.4 16.8 3.6 25.4 3.6 9.2 0 20.1 0 31.3-5.9 1.6-.9 18.4-10.1 24-28.6 3-9.8.5-21.6-4.5-44.8-2.1-9.3-4.1-16.9-5.6-22.3Z" />
  <path fill="url(#f)" d="M357.3 482.67c9.1 7.2 18.6 13.8 28.6 19.7 12.1 7 24.9 12.9 38.1 17.7-7 .4-16.8.7-28.5.6-21.4-.2-58.6-.6-100.1-12.2-34.8-9.8-67.6-25.6-96.8-46.8 25.7 13.3 53.6 21.7 82.4 24.9 25.4 2.9 51.2 1.6 76.3-3.9Z" />
  <path fill="url(#g)" d="M337.9 149.77c1.6 4.6 4.1 10.9 5.4 17.7.9 4.5 5.2 21.4 6.4 25.7 2.4 7.5 3.5 10.2 4.7 15.3 1.5.3 3 .5 4.5.6 10.4.9 20.6 2.8 30.6 5.7-4.9-3.3-9.4-7.2-13.4-11.5-5.9-6.1-5-4.7-9.9-10.7-5.8-7-11.1-14.4-15.8-22.1-4.5-7-7.6-12-12.5-20.7Z" />
  <path fill="url(#h)" d="M334.9 155.87c-8.4 10.7-17.3 20.9-26.8 30.6-8.5 8.7-17.4 17-26.8 24.8 24.3-10.8 43.5-30.7 53.6-55.4Z" />
  <ellipse cx="234.5" cy="330.17" fill="url(#b)" stroke="url(#i)" stroke-miterlimit="10" rx="5.3" ry="8.8" />
  <ellipse cx="345.8" cy="330.17" fill="url(#j)" stroke="url(#k)" stroke-miterlimit="10" rx="5.3" ry="8.8" />
  <path fill="url(#l)" d="M199.3 254.17c-5.2 7.9-9.2 16.5-11.9 25.5-4.1 13.6-4.8 28.1-2 42 .3 6.3.2 12.6-.4 18.9-1.5 16.4-5.7 32.5-12.5 47.5-3.4 7.9-4.9 16.4-4.4 25 1.5 20.8 15.5 35.1 22 41-2-4.1-4.1-8.3-6.1-12.5-4-6.6-6.7-13.9-7.7-21.6-1.2-10.6.7-21.2 5.5-30.7 7.2-14.3 11.6-29.8 12.9-45.7.8-10.6.1-21.3-2.1-31.8-1-5.5-1.6-11.2-1.6-16.8-.1-14 2.8-27.9 8.3-40.8Z" />
  <path fill="url(#m)" d="M435.6 278.67c5.7 7.5 10.6 15.5 14.8 23.9 0 0 8.3 18.9 8.1 32.2 0 1.4.7 11 .9 17.4.3 7.7.3 8.3.5 10.5.3 2.2.7 4.4 1.3 6.5 1.5 5.7 3.8 11.1 6.9 16.1 4.7 8.2 7.1 14.7 14.2 20.7-4.1-3.5-5-3.9-10.7-8.3-3.1-2.8-8-6.4-19.6-16.4-7.1-6.2-10.5-9.7-13.1-14.8-3.1-6.3-4.6-13.3-4.2-20.3-.1-3.2.3-6.4 1-9.5 1.4-5.7 3.4-7.8 4.6-13.8.5-2.6.8-5.2.8-7.8.5-13.3-4.7-33.4-5.5-36.4Z" />
    </g>
  )
});

export default veYETI;
