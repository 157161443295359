import React from "react";
import { Button, Flex, useToast } from "@chakra-ui/react";
import Tooltip from "../../../../Components/Tooltip";
import { useTransactionFunction } from "../../../../Components/Transaction";
import { useLiquity } from "../../../../Hooks/LiquityContext";
import { getNum } from "../../../../Utils/number";
import { validateAutoSell } from "../../../../Utils/validation";

export type PoolSwapProps = {
  slippage: number;
  dollarTotal: number;
  swapAssets: string[]
  close: () => void;
};

const PoolSwap: React.FC<PoolSwapProps> = ({ slippage, dollarTotal, swapAssets, close }) => {
  const { liquity } = useLiquity();

  const toast = useToast()
  const minExpectAmount = dollarTotal * (1 - slippage)
  const autoCompoundOnSubmit = (): void => {
    // console.log('dollarTotal', dollarTotal);
    // console.log('minAmount', minAmount, Math.round(dollarTotal * (1 - minAmount)));
    if (validateAutoSell(toast, minExpectAmount)) {
      autoCompound();
      close();
    }
    
  };
  const [autoCompound] = useTransactionFunction(
    "stability-pool-auto-compound",
    liquity.send.claimRewardsSwap.bind(liquity.send, minExpectAmount, swapAssets, true)
  );
  
  return (
    <Button variant="primary" mr={8} onClick={autoCompoundOnSubmit}>
      Auto-Compound{" "}
      {
        <Flex ml={1}>
          <Tooltip>
            Will also claim pending YETI rewards.
          </Tooltip>
        </Flex>
      }
    </Button>
  );
};

export default PoolSwap;
