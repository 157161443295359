import setupWeb3 from "./setupWeb3"

export const getCurrentICR = async (web3: any, userID: string, blockNum: number) => {

    let avax_web3 = web3

    if (avax_web3 == null) {
      const web3s = await setupWeb3()
      avax_web3 = web3s.avax_web3
      // console.log('got avax_web3 here', avax_web3)
    }

    const web3TroveManagerABI = require('../abi/troveManagerABI.json');

    const web3TroveManagerAddress = "0x000000000000614c27530d24B5f039EC15A61d8d"

    const web3TroveManagerContract = new avax_web3.eth.Contract(web3TroveManagerABI, web3TroveManagerAddress);

    web3TroveManagerContract.defaultBlock = blockNum

    let currentICR = await web3TroveManagerContract.methods.getCurrentICR(userID).call()

    return Number(currentICR)
  }

export default getCurrentICR