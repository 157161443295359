import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
// Coinbase
import { WalletLinkConnector } from "@web3-react/walletlink-connector"

export const injectedConnector = new InjectedConnector({});
export const walletConnectConnector = new WalletConnectConnector({
  rpc: { 43114: "https://api.avax.network/ext/bc/C/rpc" },
  qrcode: true
});
export const coinbaseConnector = new WalletLinkConnector({
  url: "https://api.avax.network/ext/bc/C/rpc",
  appName: "Yeti Finance APP",
  appLogoUrl: "../../public/favicon.ico"
})
