import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  Tr,
  Td,
  NumberInput,
  NumberInputField,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Divider
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import tokenData from "../../TokenData";
import { format, formatWithDecimals, getNum } from "../../Utils/number";
import { TokenTable, Icon } from "../../Components";
import AddCollateralTypeModal from "../Borrow/AddCollateralTypeModal";
import { CoinShow, TokenData } from "../../Types";
import { isStableCoin } from "../LiquidationCalculator/CollateralCalculator/CollateralCalculator";

type LeveredCollateral = TokenData & {
  apy: number;
  adjustedPrice: number;
  adjustedPriceString: string;
  maxAdjustedPrice: number;
  minAdjustedPrice: number;
  troveBalanceString: string;
  price: number;
  futurePrice: number;
  futureCollateralValue: number;
  futureWeightedCollateralValue: number;
  leverage: number;
  maxLeverage: number;
  leverageAdjustmentStep: number;
};

type StrategyCalculatorState = {
  collaterals: LeveredCollateral[];
  initialTroveValue: number;
  initialRiskAdjustedTroveValue: number;
  futureTroveValue: number;
  futureRiskAdjustedTroveValue: number;
  leveragedFutureTroveValue: number;
  leveragedRiskAdjustedFutureTroveValue: number;
  leveragedAPY: number;
  leveragedROI: number;
  futureTroveRoi: number;
  daysElapsed: number;
};

const selector = ({
  trove,
  underlyingPrices,
  tokenBalances,
  decimals,
  safetyRatios
}: LiquityStoreState) => ({
  trove,
  underlyingPrices,
  tokenBalances,
  decimals,
  safetyRatios
});

export interface CollateralAPYs {
  [key: string]: any;
}

const StrategiesCalculator = () => {
  const { trove, underlyingPrices, tokenBalances, decimals, safetyRatios } =
    useLiquitySelector(selector);

  // Shape collateral
  tokenData.map(
    token =>
      (token["troveBalance"] = formatWithDecimals(
        trove.collaterals[token.address],
        decimals[token.address].toNumber()
      ))
  );
  tokenData.map(
    token =>
      (token["walletBalance"] = formatWithDecimals(
        tokenBalances[token.underlying === "" ? token.address : token.underlying],
        token.underlyingDecimals
      ))
  );
  let collateral = tokenData;
  // Determine which tokens to initially show in the calculator
  const coinShow: CoinShow = {};
  collateral.forEach(coin => {
    if (coin.troveBalance === 0 || coin.token === "BC" || coin.token === "USDT") {
      coinShow[coin.token] = false;
    } else {
      coinShow[coin.token] = true;
    }
  });

  // AddCollateralTypeModal props
  const {
    isOpen: isAddCollateralTypeOpen,
    onOpen: onAddCollateralTypeOpen,
    onClose: onAddCollateralTypeClose
  } = useDisclosure();
  const [show, setShow] = useState<CoinShow>(coinShow);

  // calculate maximum leverage for a given token
  const calculateMaxLeverage = (coin: TokenData) =>
    coin.token === "DANGER"
      ? 1.6
      : Math.round(1.1 / (1.1 - format(safetyRatios[coin.address]))) -
        1 +
        format(safetyRatios[coin.address]) * 0.1;

  // calculator state
  const initialCollateral: LeveredCollateral[] = collateral
    .filter(coin => show[coin.token])
    .map(coin => {
      const coinPrice = format(underlyingPrices[coin.address]);
      const coinMaxLeverage = calculateMaxLeverage(coin);

      return {
        ...coin,
        apy: NaN,
        price: coinPrice,
        futurePrice: coinPrice,
        troveBalanceString: coin.troveBalance.toFixed(3),
        adjustedPrice: coinPrice,
        adjustedPriceString: coinPrice.toFixed(3),
        maxAdjustedPrice: isStableCoin(coin) ? coinPrice * 1.25 : coinPrice * 6,
        minAdjustedPrice: isStableCoin(coin) ? coinPrice * 0.75 : 0,
        futureCollateralValue: coin.troveBalance * coinPrice,
        futureWeightedCollateralValue:
          coin.troveBalance * coinPrice * format(safetyRatios[coin.address]),
        leverage: 1,
        maxLeverage: coinMaxLeverage,
        leverageAdjustmentStep: (coinMaxLeverage - 1) / 20
      };
    });

  let availableCollateral: TokenData[] = collateral.filter(coin => !show[coin.token]); // collateral available to add

  const initialStrategyCalculatorState: StrategyCalculatorState = {
    collaterals: initialCollateral,
    initialTroveValue: 0,
    initialRiskAdjustedTroveValue: 0,
    futureTroveValue: 0,
    futureRiskAdjustedTroveValue: 0,
    leveragedFutureTroveValue: 0,
    leveragedRiskAdjustedFutureTroveValue: 0,
    leveragedAPY: 0,
    leveragedROI: 0,
    futureTroveRoi: 100,
    daysElapsed: 365
  };

  const clearStrategyCalculatorState: StrategyCalculatorState = {
    collaterals: [],
    initialTroveValue: 0,
    initialRiskAdjustedTroveValue: 0,
    futureTroveValue: 0,
    futureRiskAdjustedTroveValue: 0,
    leveragedFutureTroveValue: 0,
    leveragedRiskAdjustedFutureTroveValue: 0,
    leveragedAPY: 0,
    leveragedROI: 0,
    futureTroveRoi: 0,
    daysElapsed: 365
  };

  const [strategyCalculatorState, setStrategyCalculatorState] = useState<StrategyCalculatorState>(
    initialStrategyCalculatorState
  );

  // APYs for each collateral
  const [APYs, setAPYs] = useState<CollateralAPYs>({} as CollateralAPYs);
  useEffect(() => {
    const tokensToFetch = tokenData.filter(coin => show[coin.token]);
    const fetchData = async () => {
      const tempAPYs: CollateralAPYs = {};
      for (var i = 0; i < Object.keys(tokensToFetch).length; i++) {
        const token = tokensToFetch[i].token;
        let url = `https://api.yeti.finance/v1/Collaterals/${token}/APY`;
        if (token === "WETH-WAVAX JLP") {
          url = "https://api.yeti.finance/v1/Collaterals/WETHWAVAXJLP/APY";
        } else if (token === "AVAX-USDC JLP") {
          url = "https://api.yeti.finance/v1/Collaterals/AVAXUSDCJLP/APY";
        }
        try {
          const response = await fetch(url, { method: "GET", mode: "cors" });
          if (!response.ok) {
            throw new Error("No live API for " + token);
          }
          let apy = await response.json();
          if (apy === undefined) {
            throw new Error("Error parsing APY");
          }
          if (isNaN(apy)) {
            apy = 0;
            console.error("API did not return APY of " + token);
          }
          tempAPYs[token] = apy;
        } catch (error) {
          console.error(error);
        }
      }
      setAPYs(tempAPYs);
    };
    // update the APYs right away upon a show change
    fetchData();
    const interval = setInterval(() => {
      // then update the APYs every 15 seconds, or until another show change
      fetchData();
    }, 15000);
    return () => clearInterval(interval);
  }, [show]);

  useEffect(() => {
    strategyCalculatorState.collaterals.map((item, index) => handleCollateralChange(item, index));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [APYs]);

  useEffect(() => {
    // update the calculator state whenever a new collateral is added
    availableCollateral = collateral.filter(coin => !show[coin.token]);
    const calculatorCollaterals = strategyCalculatorState.collaterals.map(coin => coin.token);
    const newCollaterals = collateral
      .filter(coin => !calculatorCollaterals.includes(coin.token))
      .filter(coin => show[coin.token])
      .map(coin => {
        const coinPrice = format(underlyingPrices[coin.address]);
        const coinMaxLeverage = calculateMaxLeverage(coin);
        coin.troveBalance = 0;

        return {
          ...coin,
          apy: APYs === undefined || APYs[coin.token] === undefined ? 0 : APYs[coin.token],
          price: coinPrice,
          futurePrice: coinPrice,
          troveBalanceString: coin.troveBalance.toFixed(3),
          adjustedPrice: coinPrice,
          adjustedPriceString: coinPrice.toFixed(3),
          maxAdjustedPrice: isStableCoin(coin) ? coinPrice * 1.25 : coinPrice * 6,
          minAdjustedPrice: isStableCoin(coin) ? coinPrice * 0.75 : 0,
          futureCollateralValue: coin.troveBalance * coinPrice,
          futureWeightedCollateralValue:
            coin.troveBalance * coinPrice * format(safetyRatios[coin.address]),
          leverage: 1,
          maxLeverage: coinMaxLeverage,
          leverageAdjustmentStep: (coinMaxLeverage - 1) / 20
        };
      });
    strategyCalculatorState.collaterals = [
      ...strategyCalculatorState.collaterals,
      ...newCollaterals
    ];
    setStrategyCalculatorState({
      ...strategyCalculatorState
    });
    strategyCalculatorState.collaterals.map((item, index) => handleCollateralChange(item, index));
  }, [show]);

  // Update the calculator's state when a collateral is added
  const handleCollateralChange = (collateral: LeveredCollateral, index: number) => {
    const newCollaterals = [...strategyCalculatorState.collaterals];
    collateral.futureWeightedCollateralValue =
      collateral.troveBalance *
      format(collateral.futurePrice) *
      format(safetyRatios[collateral.address]);
    collateral.futureCollateralValue = collateral.troveBalance * format(collateral.futurePrice);
    collateral.apy = APYs[collateral.token] === undefined ? NaN : APYs[collateral.token];
    newCollaterals[index] = collateral;

    const timeElapsedAPY =
      strategyCalculatorState.daysElapsed > 0
        ? 1 + collateral.apy * (strategyCalculatorState.daysElapsed / 365)
        : 1;

    const timeElapsedLeveragedAPY =
      strategyCalculatorState.daysElapsed > 0
        ? 1 + collateral.apy * (strategyCalculatorState.daysElapsed / 365) * collateral.leverage
        : 1;

    const initialRiskAdjustedTroveValue = newCollaterals.reduce(
      (total, collateral) =>
        total +
        collateral.troveBalance * collateral.price * format(safetyRatios[collateral.address]),
      0
    );
    const initialTroveValue = newCollaterals.reduce(
      (total, collateral) => total + collateral.troveBalance * collateral.price,
      0
    );

    const futureTroveValue = newCollaterals.reduce(
      (total, collateral) => total + collateral.futureCollateralValue * timeElapsedAPY,
      0
    );
    const futureRiskAdjustedTroveValue = newCollaterals.reduce(
      (total, collateral) => total + collateral.futureWeightedCollateralValue * timeElapsedAPY,
      0
    );

    const leveragedFutureTroveValue = newCollaterals.reduce(
      (total, collateral) => total + collateral.futureCollateralValue * timeElapsedLeveragedAPY,
      0
    );
    const leveragedRiskAdjustedFutureTroveValue = newCollaterals.reduce(
      (total, collateral) =>
        total + collateral.futureWeightedCollateralValue * timeElapsedLeveragedAPY,
      0
    );
    const overallLeveragedAPY =
      initialRiskAdjustedTroveValue === 0
        ? 0
        : newCollaterals.reduce(
            (total, collateral) =>
              total +
              collateral.futureWeightedCollateralValue * collateral.leverage * collateral.apy * 100,
            0
          ) / initialRiskAdjustedTroveValue;
    const leveragedROI = ((leveragedFutureTroveValue - initialTroveValue) / initialTroveValue) * 100;
    const futureTroveRoi = ((futureTroveValue - initialTroveValue) / initialTroveValue) * 100;

    setStrategyCalculatorState({
      collaterals: newCollaterals,
      initialTroveValue: initialTroveValue,
      initialRiskAdjustedTroveValue: initialRiskAdjustedTroveValue,
      futureTroveValue: futureTroveValue,
      futureRiskAdjustedTroveValue: futureRiskAdjustedTroveValue,
      leveragedFutureTroveValue: leveragedFutureTroveValue,
      leveragedRiskAdjustedFutureTroveValue: leveragedRiskAdjustedFutureTroveValue,
      leveragedAPY: overallLeveragedAPY,
      leveragedROI: leveragedROI,
      futureTroveRoi: futureTroveRoi,
      daysElapsed: strategyCalculatorState.daysElapsed
    });
  };

  // Update the calculator's state when the number of days elapsed changes
  const handleDaysChange = (days: string) => {
    strategyCalculatorState.daysElapsed = isNaN(parseInt(days)) ? 0 : parseInt(days);
    setStrategyCalculatorState({
      ...strategyCalculatorState
    });
    strategyCalculatorState.collaterals.map((item, index) => handleCollateralChange(item, index));
  };

  return (
    <Box layerStyle="card" flex={1} px={2}>
      <AddCollateralTypeModal
        isOpen={isAddCollateralTypeOpen}
        onClose={onAddCollateralTypeClose}
        show={show}
        setShow={setShow}
        availableCollateral={availableCollateral}
        borrowMode="normal"
      />
      <Box>
        <Text ml={5} whiteSpace="pre-wrap" textStyle="title3">
          Calculate Leverage Strategies
        </Text>
        <Text ml={5} mb={-6} whiteSpace="pre-wrap" fontSize="md" textColor="white">
          Simulate leverage changes to see how your trove's APY would be impacted.
        </Text>
        <Flex direction={["column", null, "row"]} flex={1} mt={6}>
          <Flex flex={1} mr={[0, null, 0]}>
            <TokenTable
              headers={[
                "Collateral",
                "Balance",
                "Current Price",
                "Future Price",
                "Leverage Adjuster",
                "Leverage",
                "",
                "Current APY",
                "",
                "Leveraged APY"
              ]}
              tooltips={[
                "A collateral in your trove",
                "The number of tokens in your trove",
                "The current market price of the collateral asset",
                "The simulated future price of the collateral asset",
                "Leverage adjuster",
                "leverage",
                "",
                "The current annual percentage rate of the collateral asset",
                "",
                "The leverage-boosted annual percentage rate of the collateral asset"
              ]}
              width={10}
            >
              {strategyCalculatorState.collaterals.map((item, index) => (
                <Tr key={index}>
                  <Td pt={6} pb={2}>
                    <Flex align="center" w={28}>
                      <Icon iconName={item.token} h={5} w={5} />
                      <Text ml={3} whiteSpace="pre-wrap">
                        {item.token}
                      </Text>
                    </Flex>
                  </Td>
                  <Td pt={6} pb={2} pl={2}>
                    <Flex align="center">
                      <NumberInput
                        precision={3}
                        value={item.troveBalanceString}
                        defaultValue={0}
                        onChange={val => {
                          handleCollateralChange(
                            {
                              ...item,
                              troveBalance: isNaN(parseFloat(val)) ? 0 : parseFloat(val),
                              troveBalanceString: val
                            },
                            index
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </Flex>
                  </Td>
                  <Td pt={6} pb={2} pl={2}>
                    <Flex align="center">
                      <Text ml={3} whiteSpace="nowrap">
                        ${getNum(item.price, 2)}
                      </Text>
                    </Flex>
                  </Td>
                  <Td pt={6} pb={2} pl={2}>
                    <Flex align="center">
                      <NumberInput
                        precision={3}
                        value={isNaN(item.futurePrice) ? "0.00" : item.futurePrice}
                        onChange={val => {
                          handleCollateralChange(
                            {
                              ...item,
                              futurePrice: parseFloat(val)
                            },
                            index
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </Flex>
                  </Td>
                  <Td pt={6} pb={2} pl={2}>
                    <Flex align="center" w={40} pr={6}>
                      <Slider
                        focusThumbOnChange={false}
                        aria-label="slider-ex-6"
                        value={item.leverage}
                        min={1}
                        max={item.maxLeverage}
                        step={item.leverageAdjustmentStep}
                        onChange={val => {
                          handleCollateralChange(
                            {
                              ...item,
                              leverage: val
                            },
                            index
                          );
                        }}
                      >
                        <SliderMark value={1} mt="1" ml="-2.5" fontSize="x-small">
                          1X
                        </SliderMark>
                        <SliderMark value={item.maxLeverage} mt="1" ml="-2.5" fontSize="x-small">
                          {item.maxLeverage.toFixed(2)}X
                        </SliderMark>
                        <SliderTrack>
                          <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                      </Slider>
                    </Flex>
                  </Td>
                  <Td pt={6} pb={2} pl={2}>
                    <Flex align="center">
                      <NumberInput
                        precision={3}
                        value={isNaN(item.leverage) ? "0" : item.leverage.toString()}
                        onChange={val => {
                          handleCollateralChange(
                            {
                              ...item,
                              leverage: parseFloat(isNaN(parseFloat(val)) ? "0" : val)
                            },
                            index
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </Flex>
                  </Td>
                  <Td pt={6} pb={2} pl={0} pr={0}>
                    <Flex align="center">
                      <Text ml={3} whiteSpace="nowrap" color="gray.500">
                        x
                      </Text>
                    </Flex>
                  </Td>
                  <Td pt={6} pb={2} pl={2}>
                    <Flex align="center">
                      <Text ml={3} whiteSpace="nowrap">
                        {(APYs[item.token] === undefined || APYs[item.token] === null) &&
                        item.apy !== undefined
                          ? item.apy.toFixed(2) + "%"
                          : APYs[item.token] !== 0
                          ? (APYs[item.token] * 100).toFixed(2) + "%"
                          : "N/A"}
                      </Text>
                    </Flex>
                  </Td>
                  <Td pt={6} pb={2} pl={0} pr={0}>
                    <Flex align="center">
                      <Text ml={3} whiteSpace="nowrap" color="gray.500">
                        =
                      </Text>
                    </Flex>
                  </Td>
                  <Td pt={6} pb={2} pl={2}>
                    <Flex align="center">
                      <Text ml={3} whiteSpace="nowrap">
                        {(APYs[item.token] === undefined || APYs[item.token] === null) &&
                        item.apy !== undefined
                          ? (item.apy * item.leverage).toFixed(2) + "%"
                          : APYs[item.token] !== 0
                          ? (APYs[item.token] * 100 * item.leverage).toFixed(2) + "%"
                          : "N/A (0%)"}
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </TokenTable>
          </Flex>
        </Flex>
        <Flex>
          <Button
            disabled={!availableCollateral.length}
            colorScheme="brand"
            variant="primary"
            _active={{ bg: "transparent" }}
            mt={10}
            mx={6}
            leftIcon={<Icon iconName="BlueAddIcon" />}
            onClick={onAddCollateralTypeOpen}
          >
            Add Collateral Type
          </Button>
          <Button
            colorScheme="brand"
            variant="primary"
            _active={{ bg: "transparent" }}
            mt={10}
            mr={6}
            onClick={() => {
              strategyCalculatorState.collaterals.map((item, index) => {
                let coinTroveBalance = 0;
                if (coinShow[item.token] === true) {
                  // get the user's trove balance of the coin, if it exists, else 0
                  const troveCoin = collateral.find(coin => coin.token === item.token) || {
                    troveBalance: 0
                  };
                  coinTroveBalance = troveCoin.troveBalance;
                }
                item.troveBalance = coinTroveBalance;
                item.troveBalanceString = coinTroveBalance.toFixed(3);
                handleCollateralChange(item, index);

                return item;
              });
            }}
          >
            Set to Your Trove Balances
          </Button>
          <Button
            colorScheme="brand"
            variant="primary"
            _active={{ bg: "transparent" }}
            mt={10}
            onClick={() => {
              setShow({});
              setStrategyCalculatorState(clearStrategyCalculatorState);
            }}
          >
            Clear
          </Button>
        </Flex>
      </Box>
      <Box my={5} px={6}>
        <Divider />
      </Box>
      <Box>
        <Text ml={5} mb={3} whiteSpace="pre-wrap" textStyle="title3">
          Leverage Impact
        </Text>
      </Box>
      <Box ml={5}>
        <Flex mb={3} align="center">
          <Text mr={4} textStyle="subtitle1">
            Days Elapsed:
          </Text>
          <NumberInput
            color="white"
            precision={3}
            value={strategyCalculatorState.daysElapsed}
            onChange={val => {
              handleDaysChange(val);
            }}
          >
            <NumberInputField />
          </NumberInput>
        </Flex>
        <Text mb={3} whiteSpace="pre-wrap" textStyle="subtitle1">
          Initial Trove Value: ${getNum(strategyCalculatorState.initialTroveValue, 2)} ($
          {getNum(strategyCalculatorState.initialRiskAdjustedTroveValue, 2)} RAV)
        </Text>
        <Text mb={3} whiteSpace="pre-wrap" textStyle="subtitle1">
          Future Trove Value : ${getNum(strategyCalculatorState.futureTroveValue, 2)} ($
          {getNum(strategyCalculatorState.futureRiskAdjustedTroveValue, 2)} RAV)
        </Text>
        <Text mb={3} whiteSpace="pre-wrap" textStyle="subtitle1">
          Leveraged Future Trove Value : $
          {getNum(strategyCalculatorState.leveragedFutureTroveValue, 2)} ($
          {getNum(strategyCalculatorState.leveragedRiskAdjustedFutureTroveValue, 2)} RAV)
        </Text>
        <Text mb={3} whiteSpace="pre-wrap" textStyle="subtitle1">
          Overall Leveraged Trove APY : {getNum(strategyCalculatorState.leveragedAPY, 2)}%
        </Text>
        <Text mb={3} whiteSpace="pre-wrap" textStyle="subtitle1">
          Future Trove ROI : {getNum(strategyCalculatorState.futureTroveRoi, 2)}%
        </Text>
        <Text whiteSpace="pre-wrap" textStyle="subtitle1">
          Future Trove ROI with Leverage : {getNum(strategyCalculatorState.leveragedROI, 2)}%
        </Text>
      </Box>
    </Box>
  );
};

export default StrategiesCalculator;
