import { createIcon } from "@chakra-ui/react";

const Coinbase = createIcon({
  displayName: "Coinbase",
  viewBox: "0 0 1000 1000",
  path: (
    <>
       <path fill="#0052FF" d="M0 0h1024v1024H0z" />
   <path fill="#fff" fill-rule="evenodd" d="M152 512c0 198.823 161.177 360 360 360s360-161.177 360-360-161.177-360-360-360-360 161.177-360 360Zm268-116c-13.255 0-24 10.745-24 24v184c0 13.255 10.745 24 24 24h184c13.255 0 24-10.745 24-24V420c0-13.255-10.745-24-24-24H420Z" clip-rule="evenodd" />
    </>
  )
});

export default Coinbase;
